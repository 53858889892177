@import './../../../../scss/theme-bootstrap';

.cart-block {
  padding: 0;
  &__content {
    padding: 0;
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: 0.25em;
  }
  &__header {
    @include pie-clearfix;
    background-color: $color-light-gray;
    padding: 1.25em 1em;
    &__item-count {
      float: right;
    }
    .cart-block--edit {
      @include swap_direction(margin, 5px 40px 0 0);
      display: none;
      float: $rdirection;
      padding: 0 5px;
      text-decoration: underline;
      text-transform: capitalize;
      @include breakpoint($medium-up) {
        @include swap_direction(margin, 0 40px 0 0);
      }
      .cart-block--nonempty &,
      .cart-block--added & {
        display: inline-block;
        line-height: 2;
      }
    }
  }
  &__products {
    overflow: auto;
    max-height: calc(84vh - 235px);
  }
  &__title {
    float: left;
    margin: 0;
    padding: 0;
    font-size: 2em;
    &-link {
      text-transform: none;
    }
    &__text {
      display: none;
      font-size: 28px;
      &--empty {
        display: inline-block;
        .cart-block--nonempty &,
        .cart-block--added & {
          display: none;
        }
      }
      &--nonempty {
        .cart-block--nonempty & {
          display: inline-block;
        }
      }
      &--added {
        .cart-block--added & {
          display: inline-block;
        }
      }
    }
  }
  &__item-count {
    color: $color-dark-gray;
    font-size: 1.25em;
    display: none;
    .cart-block--singular &--singular {
      display: block;
    }
    .cart-block--plural &--plural {
      display: none;
    }
    .cart-block--empty & {
      display: none;
    }
    .cart-block--added & {
      display: none;
    }
  }
  &__items {
    .cart-item {
      padding: 1em;
      border-bottom: solid 1px $color-light-gray;
    }
    // In the "new" state, only show the items that were just added:
    &--new {
      display: none;
      .cart-block--added & {
        display: block;
      }
    }
    .cart-block--added &--all {
      display: none;
    }
    &__view-bag {
      display: block;
      padding: 1em 0 2em;
    }
    .cart-item {
      &--info,
      &__cart-details {
        width: 79%;
        text-align: left;
      }
    }
    &--remove-form {
      display: block;
      float: right;
      text-transform: none;
    }
  }
  &__footer {
    padding: 1.25em 1em;
    background-color: $color-light-gray;
    text-align: center;
    &__summary {
      @include pie-clearfix;
      display: none;
      margin: 0 0 1em;
      .cart-block--nonempty &,
      .cart-block--added & {
        display: block;
      }
    }
    &__item-count {
      float: left;
      // Intentionally supressing item count in footer, see ORNF-122
      display: none;
      .cart-block--plural & {
        display: none;
      }
    }
    &__subtotal {
      color: $color-black;
      display: block;
      @include clearfix;
      font-family: $font--avenir-heavy;
      text-transform: none;
      &__label {
        float: left;
      }
      &__value {
        float: right;
      }
    }
    &__button {
      width: 100%;
      text-transform: none;
      margin-bottom: 1em;
      &--checkout {
        text-transform: uppercase;
        display: none;
        .cart-block--nonempty &,
        .cart-block--added & {
          display: block;
        }
      }
      &--continue-shopping {
        .cart-block--nonempty &,
        .cart-block--added & {
          display: none;
        }
      }
    }
  }
}
